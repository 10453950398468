import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

const JoinPage: React.FC = () => (
    <Layout>
        <SEO title="Join" />

        <section className="section">
            <div className="container content">
                <h1 className="title is-1">Join</h1>

                <p>
                    Due to the pandemic, we aren't meeting in person at the
                    moment, but please sign up for our mailing list in the
                    meantime. You can also{" "}
                    <Link to="/contact/">send us an email</Link>.
                </p>
                <p>
                    <a
                        className="button is-link is-large"
                        href="http://eepurl.com/bCCbYn"
                        target="_blank"
                        rel="nofollow noreferrer noopener"
                    >
                        Subscribe for Updates
                    </a>
                </p>

                <hr />

                <h2 className="title is-2">Coding Meetups</h2>
                <div className="notification is-warning is-light">
                    The in-person events are on hold at the moment because of
                    the virus, but we're working on new plans. Please{" "}
                    <Link to="/newsletter/">subscribe</Link> to stay updated.
                </div>
                <p>
                    We usually meet through the{" "}
                    <a href="https://codeselfstudy.com/">Code Self Study</a>{" "}
                    meetups which happen twice per week. They are not
                    &quot;autism-only&quot;, but are autism-friendly.
                </p>
                <ul>
                    <li>Improve your technical skills</li>
                    <li>Self-study with mentorship</li>
                    <li>
                        Break the isolation and get used to being around other
                        humans regularly
                    </li>
                    <li>
                        Make friends without any pressure (attending quietly is
                        okay)
                    </li>
                    <li>
                        Network with both autistic and non-autistic people to
                        gain industry contacts
                    </li>
                    <li>
                        Consistent -- we meet twice per week at the same times
                    </li>
                    <li>Occasional extra social events</li>
                    <li>
                        Chatroom and forum for online communication between
                        and/or during meetings
                    </li>
                    <li>
                        Autism-friendly -- you don't need to worry about making
                        social mistakes
                    </li>
                    <li>Autism-only coding events coming soon</li>
                    <li>500 events so far over the past six years</li>
                </ul>
                <p>
                    <a
                        href="https://codeselfstudy.com/events/"
                        className="btn btn-lg btn-primary"
                    >
                        Check out the event calendar
                    </a>
                </p>
            </div>
        </section>
    </Layout>
);

export default JoinPage;
